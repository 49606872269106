import * as React from "react";
import {PreviewGalleryStyled} from "./PreviewGallery.styled";
import {SignUpForm} from "../sign-up-form/SignUpForm";
import {LegendaryHead} from "../legendary-head/LegendaryHead";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

export const PreviewGallery = () => {
    const data = useStaticQuery(graphql`{
          allWpPage(filter: {id: {eq: "cG9zdDoyMzc3"}}) {
            nodes {
              legendaryNights {
                previewGallerySubtitle
                previewGalleryTitle
                previewGalleryPhotos {
                  previewGalleryImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
    `)

    const title = data?.allWpPage?.nodes[0]?.legendaryNights?.previewGalleryTitle;
    const subTitle = data?.allWpPage?.nodes[0]?.legendaryNights?.previewGallerySubtitle;
    const images = data?.allWpPage?.nodes[0]?.legendaryNights?.previewGalleryPhotos

    return (
        <PreviewGalleryStyled >
            <div className="preview-gallery">
                <div className="container">
                    <LegendaryHead title={title} subtitle={subTitle} color="white"/>
                    <div className="preview-gallery_wrapper">
                        {images.map((item, i)=> {
                            return (
                                <div key={i} className="preview-gallery_img">
                                    <GatsbyImage
                                        layout={"constrained"}
                                        width={(i === (0 || 1)) ? 900 : 450}
                                        image={getImage(item?.previewGalleryImage?.localFile?.childImageSharp?.gatsbyImageData)}
                                        alt="" />
                                </div>
                            )
                        })}
                    </div>
                    <SignUpForm />
                </div>
            </div>
        </PreviewGalleryStyled>
    )
}