import * as React from "react";
import {LegendaryHeadStyled} from "./LegendaryHead.styled";

export const LegendaryHead = ({title, subtitle, color = ''}) => {

    return (
        <LegendaryHeadStyled color={color} >
            <div className={["head", color && "white"].join(" ")}>
                <h2>{title}</h2>
                <p>{subtitle}</p>
            </div>
        </LegendaryHeadStyled>
    )
}