import styled from "styled-components"
import { sm } from "../../../styles/utils/media_queries"

export const LegendaryVideoStyled = styled.section`
    .legendary-video {
      padding: 40px 0;
      
      &_wrapper {
        margin-top: 40px;
        width: 100%;
        height: 0;
        padding-top: 52%;
        overflow: hidden;
        position: relative;
      }
      
      &_controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        button {
          position: absolute;
          left: calc(50% - 40px);
          right: 0;
          bottom: 0;
          top: calc(50% - 40px);
          width: 80px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          transition: 0.3s ease-in-out;
          cursor: pointer;
          font-size: 25px;
          color: #fff;
          background: #612C8F;
          box-shadow: none;
          border: 0;
          padding: 0 0 0 4px;
          &:hover {
            transform: scale(1.1);
          }
        }
      }

      &_poster{
        transition:0.4s;
        height: 100%;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center 30%;
        }
      }

      &_video {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        video{
          object-fit: cover;
        }
      }
    }
  
  ${sm(`
    .legendary-video {
        padding: 80px 0;
        &_wrapper {
          margin-top: 80px;
        }
    }
  `)}
`
