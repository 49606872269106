import styled from "styled-components"
import { sm, md } from "../../../styles/utils/media_queries"

export const GalleryStyled = styled.section`
  .gallery {
    padding: 40px 0;
    background: #612C8F;
    &_wrapper {
      column-count: 1;
      line-height:0;
      column-gap:4px;
      margin-top: 40px;

      @media (min-width: 576px) {
        column-count: 2;
        gap: 8px;
      }
      @media (min-width: 768px) {
        column-count: 3;
        gap: 12px;
      }

      img{
        width: 100%;
        height: auto;
        margin-bottom: 4px;
        @media (min-width: 576px) {
          margin-bottom: 8px;
        }
        @media (min-width: 768px) {
          margin-bottom: 12px;
        }
      }
    }
    
  }
  ${sm(`
      .gallery {
        padding: 80px 0;
        &_wrapper {
        margin-top: 80px;
          column-count: 4;
          gap: 16px;
          img {
              margin-bottom: 16px;
          }
        }
      }
  `)}
  
  ${md(`
    .gallery {
      &_wrapper {
        column-count: 5;
        gap: 20px;
        img {
            margin-bottom: 20px;
        }
      }
    }
  `)}
`
