import styled from "styled-components"

export const SignUpFormStyled = styled.section`
    .legendary-form {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border: 2px solid #fff;
      border-radius: 10px;
      padding: 10px;
      color: #fff;
      line-height: 1.3;
      
      &_email {
        width: calc(100% - 30px);
        font-size: 12px;
        input {
          width: 100%;
          border: 0;
          padding: 3px 5px;
          font-size: 20px;
          margin-top: 3px;
        }

        input::placeholder {
          font-size: 18px;
          font-family: Integral CF Bold;
          color: #000;
        }
      }
      
      &_submit {
        width: 20px;
        cursor: pointer;
        font-size: 16px;
        padding: 2px 0;
        
        svg {
          transition: all 0.3s ease-in-out;
        }
        input {
          display: none;
        }
        
        &:hover {
          svg {
            transform: translateX(4px);
          }
        }
      }
    }
`
