import * as React from "react";
import {LegendaryNightStyled} from "./LegendaryNight.styled";
import {PreviewGallery} from "./preview-gallery/PreviewGallery";
import {Gallery} from "./gallery/Gallery";
import {LegendaryVideo} from "./legendary-video/LegendaryVideo";

export const LegendaryNight = () => {

    return (
        <LegendaryNightStyled >
            <PreviewGallery />
            <LegendaryVideo />
            <Gallery />
        </LegendaryNightStyled>
    )
}