import styled from "styled-components"


export const LegendaryHeadStyled = styled.section`
          .head {
          text-align: center;
             h2, p {
                color: #612C8F;
             }
          
          &.white { 
             h2, p {
                color: #fff;
            }
          }

          h2 {
             font-family: Integral CF Bold;
             font-style: italic;
             font-weight: 700;
             font-size: 35px;
          }

          p {
            font-weight: 600;
            font-size: 16px;
          }
    }
    `

