import * as React from "react";
import {GalleryStyled} from "./Gallery.styled";
import {LegendaryHead} from "../legendary-head/LegendaryHead";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";

export const Gallery = () => {
    const data = useStaticQuery(graphql`{
  allWpPage(filter: {id: {eq: "cG9zdDoyMzc3"}}) {
    nodes {
      legendaryNights {
        gallerySubtitle
        galleryTitle
        galleryList {
          galleryImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
}
    `)

    const title = data?.allWpPage?.nodes[0]?.legendaryNights?.galleryTitle;
    const subTitle = data?.allWpPage?.nodes[0]?.legendaryNights?.gallerySubtitle;
    const images = data?.allWpPage?.nodes[0]?.legendaryNights?.galleryList

    return (
        <GalleryStyled >
            <div className="gallery">
                <div className="container">
                    <LegendaryHead title={title} subtitle={subTitle} color="white" />
                    <div className="gallery_wrapper">
                        {images.map((item, i)=> {
                            return (
                                <GatsbyImage
                                    key={i}
                                    layout={"constrained"}
                                    image={getImage(item?.galleryImage?.localFile?.childImageSharp?.gatsbyImageData)}
                                    width={400}
                                    alt="" />
                            )
                        })}
                    </div>
                </div>
            </div>
        </GalleryStyled>
    )
}