import styled from "styled-components"
import { sm, xxs } from "../../../styles/utils/media_queries"

export const PreviewGalleryStyled = styled.section`
  
    .preview-gallery {
      padding: 40px 0;
      background: #612C8F;
      
      &_wrapper {
        padding: 40px 0;
      }
      
      &_img {
        width: 100%;
        position: relative;
        margin: 10px 0;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  
  ${xxs(`
    .preview-gallery {
      &_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
      &_img {
        margin: 10px;
        width: calc(50% - 20px);
      }
    }
  `)}
  
  ${sm(`
    .preview-gallery {
      padding: 80px 0;
      &_wrapper {
      padding: 80px 0;
       display: grid;
       grid-template-columns: repeat(3, 1fr);
       grid-template-rows: repeat(4, 1fr);
       gap: 20px;
      }
      &_img {
      width: 100%;
      margin: 0;
        &:first-of-type {
             grid-area: 1 / 1 / 3 / 3;
        }
        &:nth-of-type(2) { 
          grid-area: 3 / 1 / 5 / 3;
         }
        &:nth-of-type(3) { 
          grid-area: 1 / 3 / 2 / 4; 
        }
        &:nth-of-type(4) { 
          grid-area: 2 / 3 / 3 / 4; 
        }
        &:nth-of-type(5) { 
          grid-area: 3 / 3 / 4 / 4; 
        }
        &:last-of-type { 
          grid-area: 4 / 3 / 5 / 4; 
        }
      }
    }
  `)}
  
`
