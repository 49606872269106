import * as React from "react";
import {SignUpFormStyled} from "./SignUpForm.styled";
import {LegendaryHead} from "../legendary-head/LegendaryHead";
import {FaArrowRight} from "react-icons/all";
import {graphql, useStaticQuery} from "gatsby";

export const SignUpForm = () => {
    const data = useStaticQuery(graphql`{
          allWpPage(filter: {id: {eq: "cG9zdDoyMzc3"}}) {
            nodes {
              legendaryNights {
                legandarySignupTitle
                legandarySignupSubtitle
              }
            }
          }
        }
    `)

    const title = data?.allWpPage?.nodes[0]?.legendaryNights?.legandarySignupTitle;
    const subTitle = data?.allWpPage?.nodes[0]?.legendaryNights?.legandarySignupSubtitle;

    return (
        <SignUpFormStyled >
            <LegendaryHead title={title} subtitle={subTitle} color="white"/>
            <form action="https://www.getdrip.com/forms/334923706/submissions" method="post" className="legendary-form" target="_blank">
                    <label className="legendary-form_email">
                        Email
                        <input type="email" name="fields[email]" autoComplete="email" />
                    </label>
                    <label className="legendary-form_submit">
                        <FaArrowRight />
                        <input type="submit" value="Sign Up" data-drip-attribute="sign-up-button" />
                    </label>
            </form>

        </SignUpFormStyled>
    )
}