import * as React from "react";
import {LegendaryHead} from "../legendary-head/LegendaryHead";
import {LegendaryVideoStyled} from "./LegendaryVideo.styled";
import {graphql, useStaticQuery} from "gatsby";
import ReactPlayer from "react-player/lazy";
import {useState} from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {FaPlay} from "react-icons/all";

export const LegendaryVideo = () => {
    const data = useStaticQuery(graphql`{
          allWpPage(filter: {id: {eq: "cG9zdDoyMzc3"}}) {
            nodes {
              legendaryNights {
                legendaryVideoSubtitle
                legendaryVideoTitle
                legendaryVideo {
                  guid
                }
                galleryVideoPoster {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
    `)

    const [playerStart , setPlayerStart]=useState(false);

    const banner = data?.allWpPage?.nodes[0]?.legendaryNights?.galleryVideoPoster?.localFile?.childImageSharp?.gatsbyImageData;
    const title = data?.allWpPage?.nodes[0]?.legendaryNights?.legendaryVideoTitle;
    const subTitle = data?.allWpPage?.nodes[0]?.legendaryNights?.legendaryVideoSubtitle;
    const videoGuid = data?.allWpPage?.nodes[0]?.legendaryNights?.legendaryVideo?.guid;

    const  handlePosterClick = () => {
        setPlayerStart(true);
    }

    return (
        <LegendaryVideoStyled>
            <div className="legendary-video">
                <div className="container">
                    <LegendaryHead title={title} subtitle={subTitle}/>

                    <div className="legendary-video_wrapper">
                        {playerStart ?
                            <ReactPlayer
                                className="legendary-video_video"
                                url={videoGuid}
                                playing={true}
                                controls={true}
                                muted={false}
                                width="100%"
                                height="100%" />
                            :
                            <div className="legendary-video_controls">
                                <GatsbyImage
                                    width={1200}
                                    className="legendary-video_poster"
                                    image={getImage(banner)}
                                    alt={'banner'} />

                                <button onClick={handlePosterClick}>
                                    <FaPlay />
                                </button>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </LegendaryVideoStyled>
    )
}