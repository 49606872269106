import * as React from "react"
import Seo from "../components/layout/Seo";
import {LegendaryNight} from "../components/legendary-night/LegendaryNight";

const LegendaryNightPage = () => {


    return (
        <>
            <Seo title="Legendary Nights" />
            <LegendaryNight />
        </>
    );
}

export default LegendaryNightPage;
